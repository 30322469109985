@import "globals";
/***  Navigation top  ***/
.navigation-top {
  //position: absolute;
  left: 4px;
  //top: 69px;
  list-style: none;
  //background-image: url(/static/img/meni_back.jpg);
  //background-color: #F4F4F4;
  height: 105px;

  $gradientPosition: 28%;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#f4f4f4+54,e5e5e5+100 */
  background: #f4f4f4; /* Old browsers */
  background: -moz-linear-gradient(top,  #f4f4f4 $gradientPosition, #e5e5e5 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  #f4f4f4 $gradientPosition, #e5e5e5 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  #f4f4f4 $gradientPosition, #e5e5e5 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  
  width: $pageWidth;
  padding: 0px;
  margin: 0px;

  &__item {
    float: left;
    padding-left: 7px;
    padding-right: 6px;
    font-size: 12px;
    font-family: Ubuntu;
    text-align: center;
    width: 112px;
    padding-top: 0px;
    line-height: 1.1em;
    position: relative;
    height: 95px;
    background-repeat: no-repeat;
    background-position: 50% 0;

    .navigation-top--hr & {
      width: 115px;
    }

    &--resetke {
      background-image: url('/static/img/navigation/top/menu-icon--resetke.png');
      .navigation-top--at & {
        background-position: 18% 52%;
      }
    }
    &--perforirana-plocevina {
      background-image: url('/static/img/navigation/top/menu-icon--perforirana-plocevina.png');
      .navigation-top--at & {
        background-position: 14% 56%;
      }
    }
    &--ekspandirana-plocevina {
      background-image: url('/static/img/navigation/top/menu-icon--ekspandirana-plocevina.png');
      .navigation-top--at & {
        background-position: 11% 56%;
      }
    }
    &--valovite-mreze {
      background-image: url('/static/img/navigation/top/menu-icon--valovite-mreze.png');
    }
    &--varjene-mreze {
      background-image: url('/static/img/navigation/top/menu-icon--varjene-mreze.png');
    }
    &--plocevinaste-resetke {
      background-image: url('/static/img/navigation/top/menu-icon--plocevinast-resetke.png');
    }
    &--okovje-za-vrata {
      background-image: url('/static/img/navigation/top/menu-icon--okovje-za-vrata.png');
    }
    &--corten {
      background-image: url('/static/img/navigation/top/menu-icon--corten.png');
    }
    /*
    &--codina {
      background-image: url('/static/img/navigation/top/menu-icon--codina.png');
    }
     */
    
    &--streck-metal {
      background-image: url('/static/img/navigation/top/menu-icon--ekspandirana-plocevina.png');
    }
    
    &:after {
      position: absolute;
      right: 1px;
      top: 19px;
      content: '';
      border-left: solid 1px white;
      border-right: solid 1px #fafafa;
      width: 1px;
      background-color: #dadada;
      height: 55px;
    }
    &:last-of-type:after {
      display: none;
    }

    A {
      text-decoration: none;
      height: 37px;
      display: table;
      position: relative;
      padding-top: 57px;
      color: #696969;

      SPAN {
        display: inline-block;
        display: table-cell;
        vertical-align: middle;
        width: 110px;
        height: 36px;
      }

      &:hover SPAN {
        color: #11598C;
      }

    }

    &.active {
      A{
        color: #11598C;
        font-weight: 500;
      }

      DIV.menu_arrow {
        position: absolute;
        left: 53px;
        bottom: -9px;
        display: block;
        background-image: url(/static/img/sprite_icons_png.png);
        background-position: -21px 0px;
        width: 20px;
        height: 10px;
      }
    }

    A DIV.menu_arrow {
      display: none;
    }
  }
}

UL.navigation-top.nr-of-cols-3{
  $padding-left: 0px;
  padding-left: $padding-left;
  width: 1000px - $padding-left;

  LI {
    box-sizing: border-box;;
    width: 31%;
    height: 103px;
    text-align: left;
    margin-left: 0;
    margin-right: 3%;
    font-size: 18px;
    DIV.menu_arrow{
      left: 68px;
      bottom: 0px;
    }
    A {
      $padding-left: 44%;
      $padding-top: 22px;
      width: 100% - $padding-left;
      height: 93px - $padding-top;
      display: block;
      padding-left: $padding-left;
      padding-top: $padding-top;
    }
    A SPAN{
      width: 100%;
      height: 50px;
    }
    &:first-child{
      A{
        $padding-left: 47%;
        padding-left: $padding-left;
        width: 100% - $padding-left;
      }
      .menu_arrow{
        left: 80px;
      }
    }
    &:last-child{
      width: 32%;
      margin-right: 0;
      A{
        $padding-left: 40%;
        padding-left: $padding-left;
        width: 100% - $padding-left;
      }
      .menu_arrow{
        left: 65px;
      }
    }
  }
}

/***  Navigation top banner ***/
#page_banner {
  font-weight: 400 !important;
  font-style: normal !important;
  //width: 945px;
  width: $pageWidth;
  box-sizing: border-box;
  height: 100px;
  /*+placement:anchor-top-left 4px 164px;*/
  //position: absolute;
  left: 4px;
  top: 173px;
  background-image: url(/static/img/banner_base.jpg);
  color: #FFFFFF;
  font-size: 37px;
  font-family: $fontFamilyStandard;
  padding-top: 46px;
  padding-left: 15px;
  text-shadow: 1px 0px 5px #474747;
  text-decoration: none;
  background-color: #256797;
  background-position: 100% 0;
  background-repeat: no-repeat;
  
  &.banner_resetke {
    background-image: url(/static/img/banner_resetke.jpg);
  }
  &.banner_perforirana {
    background-image: url(/static/img/banner_perforirana.jpg);
  }
  &.banner_ekspandirana {
    background-image: url(/static/img/banner_ekspandirana.jpg);
  }
  &.banner_valovite-mreze {
    background-image: url(/static/img/banner_valovite.jpg);
  }
  &.banner_varjene-mreze {
    background-image: url(/static/img/banner_varjene.jpg);
  }
  &.banner_plocevinaste-resetke {
    background-image: url(/static/img/banner_plocevinaste.jpg);
  }
  &.banner_vodila {
    background-image: url(/static/img/banner_vodila.jpg);
  }
  &.banner_lochbleche{
    background-image: url(/static/img/de_at/banner_lochbleche.jpg);
  }
}

.kategorija_dokumentov {
	list-style: none;
	padding-left: 0;
	margin-right: -2%;
	}

UL.kategorija_dokumentov  LI {
	float: left;
	display: block;
	width: 180px;
	overflow: hidden; /* remove this */
    margin: 0 2% 21px 0;
    position: relative;
	}

.kategorija_dokumentov  LI .stock {
    background-repeat: no-repeat;
    width: 52px;
    height: 52px;
    position: absolute;
    top: 5px;
    right: 5px;
}

.kategorija_dokumentov  LI .stock--on-stock {
    background: url(/static/img/stock/zaloga.png);
    background-image: url(/static/img/stock/zaloga.svg), none;
}

.kategorija_dokumentov  LI .stock--not-on-stock {
    background: url(/static/img/stock/po-narocilu.png);
    background-image: url(/static/img/stock/po-narocilu.svg), none;
}

BODY.hr .kategorija_dokumentov  LI .stock--on-stock {
    background: url(/static/img/hr/stock/zaliha.png);
    background-image: url(/static/img/hr/stock/zaliha.svg), none;
}

BODY.hr .kategorija_dokumentov  LI .stock--not-on-stock {
    background: url(/static/img/hr/stock/narudzba.png);
    background-image: url(/static/img/hr/stock/narudzba.svg), none;
}

.kategorija_dokumentov .title {
	font-size: 13px;
	font-weight: bold;
	background-color: #555555;
	color: white;
	padding: 5px;
	margin-top: 2px;
	position: relative;
	}

.kategorija_dokumentov .title a{
	color: #FFFFFF;
	text-decoration: none;
	}

.kategorija_dokumentov .image{
	height: 102px;
	width: 53px;
	overflow: hidden;
	text-decoration: none;
	}

.kategorija_dokumentov .arrows {
	position: absolute;
	right: 8px;
	}


.kategorija_dokumentov.skica li{
	display:block;
	width: 535px;
	margin-bottom: 26px;
	}

.kategorija_dokumentov .skica {
	text-decoration: none;
	}

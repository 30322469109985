.h2_resetke {
  color: #555555;
  font-size: 16px;
  margin: 0px 0 24px 0;
}

.highlights-gallery {
  margin-bottom: 52px;

  .image-viewer {
    float: left;
    width: 222px;

    .big {
      padding: 1px;
      border: 1px solid #e1e1e1;
      margin-bottom: 4px;
    }

    .thumbs {
      width: 234px;
    }

    .small {
      padding: 1px;
      border: 1px solid #e1e1e1;
      margin-right: 4px;
      margin-bottom: 4px;
      display: block;
      float: left;
    }
  }
}

.highlight-points {
  width: 310px;
  float: left;
  padding: 0 0 0 20px;
  list-style: none;
  margin: 0;

  &__item {
    font-size: 12px;
    margin-bottom: 15px;
    padding-left: 28px;
    background-image: url(/static/img/kljukica.png);
    background-position: 0 3px;
    line-height: 21px;
    background-repeat: no-repeat;
  }
}


.povprasevanje_form {
  margin-top: 40px;
  width: 564px;
  font-size: 12px;

  INPUT, TEXTAREA {
    border: 1px solid #dddddd;
    padding: 5px;
    width: 227px;
    background-color: #ffffff;
    font-size: 12px;
  }
  
  SELECT {
    width: 237px;
    padding: 5px;
    border: 1px solid #dddddd;
    background-color: #ffffff;
    font-size: 12px;
  }

  H2 {
    background-color: #fcfcfc;
    border-color: #efefef;
    border-style: solid;
    border-width: 1px 1px 0px 1px;
    padding: 10px 20px;
    margin-bottom: 0px;
  }

  H3 SPAN {
    color: #777777;
    font-weight: normal;
  }

  .form {
    margin-top: 23px;
  }

  .split {
    width: 263px;
    height: 300px;
    padding-left: 20px;
    padding-bottom: 20px;
    background-color: #ffffff;
    border-width: 1px;
    border-style: solid;
    border-color: #efefef;
  }

  .produkt {
    border-right-width: 0px;
    padding-right: 1px;
  }

  .formRow {
    margin-bottom: 11px;
    margin-top: 12px;
  }

  .label {
    font-size: 13px;

    .required_field {
      color: #9B1717;
    }
  }
}

.povprasevanje_form .send {
  background-color: #fcfcfc;
  border-color: #efefef;
  border-width: 0 1px 1px 1px;
  border-style: solid;
  padding: 10px 20px;
  text-align: right;

  .submit_btn {
  /*float: right;*/
    background-image: url(/static/img/gumb_oddaj_povprasevanje.png);
    text-indent: -9999px;
    width: 195px;
    height: 34px;
    border: none;

    BODY.de_at &{
      background-image: url(/static/img/de_at/anfrage.png);
      width: 160px;
    }
    BODY.hr &{
      background-image: url(/static/img/hr/upit.png);
      width: 125px;
      height: 33px;
    }
  }
}



.povprasevanje-form .formRowCheckbox {
  margin-top: 23px;
}

.formRowCheckbox .data {
  width: 24px;
  float: left;
}

.formRowCheckbox .data input {
  width: 24px;
}

.formRowCheckbox .label {
  float: left;
  width: 228px;
  font-size: 11px;
}

#tockovna_nosilnost {
  width: 90px;
}

.povprasevanje_success {
  background-color: #11598C;
  font-size: 22px;
  font-weight: bold;
  padding: 20px;
  color: #ffffff;
}

.povprasevanje-form {
  font-size: 12px;
  
  &__sirina-dolzina-wrap {
    INPUT {
      width: 82px;
    }
  }

  &__st-kosov input {
    width: 80px;
    margin-right: 5px;
  }

  &__tockovna-nosilnost input {
    width: 80px;
    margin-right: 5px;
  }
  
  &__multiply-sign {
    font-size: 20px;
    display: inline-block;
    position: relative;
    top: 2px;
    margin: 0 3px;
  }
  
  &__opombe {
    width: 237px;
    height: 75px;
    border: 1px solid #dddddd;
    background-color: #ffffff;
  }

  &__postna-st {
    width: 52px !important; //BASTODO refactor this
    margin-right: 4px;
  }

  &__kraj {
    width: 154px !important; //BASTODO refactor this
  }
  
  &__checkbox-wrap {
    margin-top: 23px !important; //BASTODO refactor this
  }

  &__checkbox-label {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
  }

  &__errors {
    padding: 0 0 0 0;
    margin: 3px 0 0 0;
    color: #ee2c2e;
    width: 163px;
    
  }
  
  &__errors-item {
    padding-left: 0;
  }
}

.catalog-popup-cont{
  width: 520px;
  padding: 20px 33px 18px;
  margin: 0 auto;
  background-color: white;
  position: relative;
}

.catalog-order-form{
  position: relative;
  color: #555555;

  H1{
    margin-top:5px;
    margin-bottom: 0;
    //padding-left: 90px;
    font-size: 27px;
  }

  .subtitle{
    font-family: $fontFamilyStandard;
    font-size: 16px;
    //padding-left: 90px;
  }

  .catalog-img-desc{
    @include clearfix;
    margin-top: 20px;

    .catalog-image{
      position: absolute;
      left: 40px;
      bottom: 68px;
      width: 26%;
      float: left;
    }

    .catalog-description{
      width: 71%;
      position: relative;
      left: -15px;
      float: left;
      margin-top: 10px;
      line-height: 1.9em;
    }
  }

  FORM{
    position: relative;
    z-index: 150;
  }

  .addressdg{
    margin-top:0;
    padding-top: 1px;
    padding-bottom: 1px;
    .formRow{
      margin-top:0;
    }
  }

  .catalogtypedg{
    margin-bottom: 0;
  }
}

.catalog-order-form FORM{

  .formRow{
    @include clearfix;
    margin: 10px 0;

    &.error INPUT[type=text]{
      border-color: #a01d2b;
    }

    INPUT[type=text]{
      padding: 5px 6px;
      font-size: 15px;
      width: 97%;
      border: 1px solid #CCCCCC;
    }
    LABEL{
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      -o-user-select: none;
      user-select: none;
    }
    .label, .data{
      float: left;
      line-height: 30px;
    }
    .label{
      width: 155px;
      text-align: right;
      margin-right: 10px;
    }
    .data{
      width: 330px;
    }
    &.newsletter-disclaimer {
      margin-top: 0;
      .data{
        font-size: 11px;
        line-height: 1.5em;
        margin-bottom: 10px;
      }
    }
  }
}

.catalog-order-form {

  .submit-odposlji{
    background-color: transparent;
    background-image: url(/static/img/sl/btn-send.png);
    width: 101px;
    height: 33px;
    @include hide-text();
    border: 0;
    &.de_AT{
      background-image: url(/static/img/de_at/btn-send.png);
      width: 108px;
    }
    &.hr {
      background-image: url(/static/img/hr/btn-posalji.png);
      width: 95px;
    }
    &.loading {
      background: url("/static/img/ajax-loader-white-bg.gif") no-repeat;
      outline: none;
    }
  }

  .disclaimer{
    font-size: 11px;
    padding: 15px 0 0;
    text-align: center;
    width: 98%;
    color: #888888;
  }
}

.catalog-order-form {
  .error-message {
    @include border-radius(3px);
    display: inline-block;
    position: relative;
    margin-top: 7px;
    margin-left: 5px;
    padding: 7px 20px 7px 30px;
    background: #a01d2b;
    color: #fff;
    font-size: 12px;
    line-height: 12px;
    &:after {
      position: absolute;
      top: -12px;
      left: 10px;
      content: "";
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      border: solid 6px;
      border-color: transparent transparent #a01d2b transparent;
    }

    LI{
      margin: 4px 0;
      list-style: disc;
    }
  }
}

.catalog-download-confirmation {
  color: #888888;

  H2{
    margin-bottom: 7px;
  }

  .cdc-content{
    margin-top: 20px;
    @include clearfix();
  }

  .cdc-catalog-image{
    float: left;
    width: 55%;
  }

  .cdc-download-explanation{
    float: left;
    font-size: 18px;
    padding: 50px 3% 0vizien;
    width: 45%;
    .cdc-countdown{
      font-weight:bold;
    }
  }

  .cdc-direct-link{
    margin-top: 25px;
    font-size: 12px;
  }

  .cdc-printed-confirmation{
    font-size: 18px;
    margin-bottom: 35px;
  }
}
UL.content-3-columns{
  list-style: none;
  padding-left: none;
  margin: 15px 0;
  @include clearfix();
  LI{
    list-style: none;
    padding-left: 0;
    width: 32%;
    float:left;
    margin: 0 2% 0 0;
    &.last{
      margin: 0;
    }

    H2, H3{
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

BODY.editmode UL.content-3-columns{
  background-color: #f2f2f2;
  border: 1px solid #dddddd;

}
@import "_utilities";

/*
** Master naročilnica seznam
*/
#lastnosti_narocila>DIV {
  margin-bottom: 15px;
  margin-right: 8px;
  padding: 14px 20px 0px;
  float: left;
  background-color: #f2f2f2;
  height: 98px;

}

#lastnosti_narocila H3 {
  font-size: 13px;
  font-weight: bold;
  margin: 0px 0px 5px 0px;
}

#lastnosti_narocila {
  @include clearfix();
  .nacin_placila {
    width: 230px;
  }

  .nacin_placila UL {
    margin-top: 10px;
    LI {
      margin-bottom: 10px;
    }
  }

  .opombe {
    width: 395px;
    TEXTAREA {
      border: 1px solid #cccccc;
      padding: 5px;
      width: 390px;
      height: 50px;
    }
  }
}

#master_narocilnica {
  width: 903px;

  UL.seznam {
    list-style: none;
    padding-left: 0px;

    .product_id {
      font-weight: bold;
      font-size: 15px;
    }
  }

}

.master_narocilnica_seznam {
  width: 903px;

  TH {
    background-color: #f2f2f2;
    padding: 8px 12px 7px;
    text-align: left;
  }

  TR {
    border: solid #e9e9e9 0;
    border-bottom-width: 1px;
  }

  TD {
    padding: 17px 12px;
    font-family: Arial, Helvetica, sans-serif;
    color: #333333;
  }
}

.master_narocilnica_seznam td.artikel {
  width: 249px;

  STRONG {
    font-size: 15px;
    font-weight: bold;
    color: #11598C;
    padding-right: 20px;
  }

  .sifra {
    font-weight: bold;
    color: #555555;
  }
}

.master_narocilnica_seznam .opis {
  font-size: 10px;
  width: 210px;
}

.master_narocilnica_seznam .opis P {
  margin: 0;
}

.master_narocilnica_seznam .cena_kos {
  text-align: center;
}

.master_narocilnica_seznam .kolicina {
  text-align: center;
}

.master_narocilnica_seznam .kolicina input {
  width: 26px;
}

.master_narocilnica_seznam .kolicina .osvezi {
  color: #777777;
}

.master_narocilnica_seznam .cena_skupaj {
  text-align: center;
}

.master_narocilnica_seznam .odstrani {
  text-align: center;
  width: 74px;
  padding-left: 0;
  padding-right: 0;
}

.master_narocilnica_skupaj {
  background-color: #f2f2f2;
  float: right;
}

.master_narocilnica_skupaj tr td {
  padding: 10px 10px;
}

.master_narocilnica_skupaj tr.skupaj_brez_ddv {
  font-size: 12px;
}

.master_narocilnica_skupaj tr.skupaj_ddv {
  font-size: 12px;
}

.master_narocilnica_skupaj tr.skupaj_z_ddv {
  font-size: 15px;
  color: #11598C;
  font-weight: bold;
}

.master_narocilnica_skupaj .key {
  width: 178px;
  text-align: right;
}

.master_narocilnica_skupaj .value {
  width: 80px;
}

/*
** Fin naročilnica seznam
*/
.fin_narocilnica_seznam {
  width: 800px;
}

.fin_narocilnica_seznam th {
  background-color: #f2f2f2;
  padding: 8px 12px 7px;
  text-align: left;
}

.fin_narocilnica_seznam tr {
  border: solid #e9e9e9 0;
  border-bottom-width: 1px;
}

.fin_narocilnica_seznam td {
  padding: 17px 12px;
  font-family: Arial, Helvetica, sans-serif;
  color: #333333;
}

.fin_narocilnica_seznam .artikel {
  width: 219px;
  padding-right: 20px;
}

.fin_narocilnica_seznam .artikel h3 {
  padding-top: 0px;
  margin-top: 0px;
  font-size: 15px;
  font-weight: bold;
  color: #11598C;
  margin-bottom: 10px;
}

.fin_narocilnica_seznam .opis {
  font-size: 11px;
  width: 240px;
}

.fin_narocilnica_seznam .cena_kos {
  text-align: center;
}

.fin_narocilnica_seznam .kolicina {
  text-align: center;
}

.master_narocilnica_seznam .cena_skupaj {
  text-align: center;
}

.fin_narocilnica_skupaj {
  background-color: #f2f2f2;
  width: 290px;
  float: right;
}

.fin_narocilnica_skupaj tr td {
  padding: 10px 10px;
}

.fin_narocilnica_skupaj tr.skupaj_brez_ddv {
  font-size: 12px;
}

.fin_narocilnica_skupaj tr.skupaj_ddv {
  font-size: 12px;
}

.fin_narocilnica_skupaj tr.skupaj_z_ddv {
  font-size: 15px;
  color: #11598C;
  font-weight: bold;
}

.fin_narocilnica_skupaj .key {
  width: 257px;
  text-align: right;
}

.fin_narocilnica_skupaj .value {
  width: 102px;
}

/* Naročilnica progress */
ul.narocilnica_progress {
  list-style: none;
  padding: 0px;
  margin: 0px;
  width: 920px;
  height: 95px;
}

hr.active_step_identifier {
  border: 0px;
  border-bottom: 1px solid #E0E0E0;
  height: 1px;
  margin: 0 0 20px 0px;
  width: 902px;
}

.narocilnica_progress li {
  float: left;
  color: #525252;
  width: 22%;
  /*padding-right: 30px;*/
  padding-left: 60px;
  position: relative;
  margin-top: 26px;
  margin-left: 26px;
  padding-top: 5px;

  &.step_2 {
    width: 28%;
  }

  &.step_3 {
    width: 20%;
  }
}

.narocilnica_progress li.active .selected_cont {
  position: absolute;
  width: 33px;
  height: 12px;
  background-color: #FFFFFF;
  background-image: url(/static/img/progress_crta_puscica.png);
  background-repeat: no-repeat;
  top: 59px;
  left: 8px;
}

.narocilnica_progress li a {
  text-decoration: none;
  color: #525252;
}

.narocilnica_progress li.active a {
  text-decoration: none;
  color: #11598C;
}

.narocilnica_progress li.active {
  color: #11598C;
}

.narocilnica_progress li.active strong {
  color: #11598C;
}

.narocilnica_progress li strong {
  font-size: 17px;
  color: #474747;
  display: block;
}

.narocilnica_progress .step_nr {
  background: url("/static/img/progress_gumbi.png") no-repeat scroll -3px 0 transparent;
  background-position: -1px 0px;
  height: 50px;
  width: 50px;
  position: absolute;
  top: 0;
  left: 0;
}

.narocilnica_progress .step_1 .step_nr {
  background-position: -1px 0;
}

.narocilnica_progress .step_1.active .step_nr {
  background-position: -1px -61px;
}

.narocilnica_progress .step_2 .step_nr {
  background-position: -67px 0;
}

.narocilnica_progress .step_2.active .step_nr {
  background-position: -67px -61px;
}

.narocilnica_progress .step_3 .step_nr {
  background-position: -128px 0;
}

.narocilnica_progress .step_3.active .step_nr {
  background-position: -128px -61px;
}

/* Registracija */
/*
** Registracija
*/

.registracija_form {
  width: 100%;
  margin-top: 17px;

  .formRow {
    padding: 7px 0;
    &.error{
      INPUT{
        //background-color: #ffb3b3;
        border: 1px solid #841221;
      }
    }
  }

  .error-message {
    @include border-radius(3px);
    //display: inline-block;
    max-width: 150px;
    position: relative;
    margin-top: 7px;
    margin-left: 5px;
    padding: 7px 20px 7px 30px;
    background: #a01d2b;
    color: #fff;
    font-size: 12px;
    line-height: 12px;
    &:after {
      position: absolute;
      top: -12px;
      left: 10px;
      content: "";
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      border: solid 6px;
      border-color: transparent transparent #a01d2b transparent;
    }

    LI{
      margin: 4px 0;
      list-style: disc;
    }
  }
}


#eposta_cont {
  margin-top: 15px;
}

#Registriraj_cont {
  margin-top: 15px;
  margin-bottom: 18px;
}

.postna-st-field {
  width: 49px !important;
  margin-right: 6px;
}

.registracija_form .fieldset {
  padding: 10px 0;
  margin: 10px 0;
}

.registracija_form #form_company_info_is_podjetje {
  width: 17px;
  height: 17px;
  margin-top: 5px;
}

.registracija_form .fieldset.podjetje {
  padding: 0;
  margin: 13px 0 0;
}

.registracija_form .formRow .label {
  width: 142px;
  display: inline-block;
  text-align: right;
  padding-right: 10px;
  vertical-align: top;
  padding-bottom: 0;
  padding-top: 7px;
  position: relative;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

.registracija_form .formRow .data {
  display: inline-block;
  text-align: left;
}

.registracija_form .formRow .data input[type="text"] {
  padding: 0 4px;
  width: 199px;
}

.registracija_form .errors {
  padding: 0 0 0 15px;
  margin: 3px 0 0 0;
  color: #ee2c2e;
  width: 163px;
}

.required_field {
  color: #ee2c2e;
}

.id_ddv {
  display: none;
}

.is_ddv .id_ddv {
  display: inline;
}

.is_ddv input {
  width: 156px !important;
}

.registracija-left {
  margin-right: 17px;
  margin-left: 35px;
}

.registracija-right {
}

.registracija-cont {
  padding: 20px 25px 24px 19px;
  border: 1px solid #E0E0E0;
  width: 371px;
  /*float:left;*/
  margin: 0 auto;
  margin-top: 15px;
}

.registracija-cont .form_toggle {
  outline: none;
  text-decoration: none;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: bold;
}

.registracija-cont .form_toggle em {
  font-style: normal;
}

.registracija-cont.closed .form_toggle em {
  text-decoration: underline;
}

.registracija-cont.closed .form_toggle span {
  text-decoration: underline;
}

.registracija-cont .form_toggle span {
  color: #AAAAAA;
  font-weight: normal;
}

.registracija-cont .arrows {
  display: none;
}

.registracija-cont.closed .arrows {
  display: inline;
}

.registracija_loggedin {
  border: 1px solid #E0E0E0;
  width: 765px;
  margin-left: 35px;
  padding: 20px 25px 24px 19px;
}

.registracija-cont input {
  border: 1px solid #CCCCCC;
  height: 25px;
  width: 185px;
}

#postna_st_cont #kraj {
  width: 131px;
}

#Registriraj, .submit-btn--registration {
  background: url(/static/img/gumb_nadaljuj.png);
  border: none 0;
  width: 205px;
  height: 34px;
  text-indent: -9999px;
  padding: 0;
  margin: 0;

  BODY.de_at &{
    background: url(/static/img/de_at/weiter.png);
    width: 89px;
    height: 33px;
  }

  BODY.hr &{
    background: url(/static/img/hr/nastavi-na-slanje.png);
    width: 161px;
    height: 34px;
  }
}

input#prijavi_se {
  background: url("/static/img/gumb_prijavi_se.png") no-repeat scroll -2px -2px transparent;
  border: none 0;
  width: 115px;
  height: 34px;
  text-indent: -9999px;
  padding: 0;
  margin: 0;
  BODY.hr &{
    background: url(/static/img/hr/prijava.png) no-repeat scroll transparent;
  }
}

.koncni_pregled_narocila {
  width: 759px;
  border: 1px solid #DDDDDD;
  padding: 30px;
  -webkit-box-shadow: 0px 0px 7px rgba(50, 50, 50, 0.3);
  -moz-box-shadow: 0px 0px 7px rgba(50, 50, 50, 0.3);
  box-shadow: 0px 0px 7px rgba(50, 50, 50, 0.3);
  margin-left: 31px;
  margin-bottom: 30px;
  position: relative;
}

.koncni_pregled_narocila .fin_narocilnica_seznam {
  width: 100%;
}

.koncni_pregled_narocila .cena_skupaj,
.koncni_pregled_narocila .cena_kos {
  width: 110px;
}

.koncni_pregled_narocila .cena_skupaj {
  text-align: center;
  font-weight: bold;
}

.koncni_pregled_narocila .title_narocilnica {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 50px;
}

.koncni_pregled_narocila .title_narocilnica span {
  background-color: #EEEEEE;
  padding: 10px 30px 10px 15px;
  margin: 0;
}

.koncni_pregled_narocila .artikel {
  width: 310px;
}

.koncni_pregled_narocila .cena_skupaj {
  width: 110px;
}

.koncni_pregled_narocila .logo {
  margin: 30px 0 50px 0;
}

.koncni_pregled_narocila .kupec {
  border: 1px solid #EEEEEE;
  font-size: 15px;
  line-height: 23px;
  font-weight: bold;
  margin-top: 40px;
  margin-bottom: 50px;
  width: 300px;
  padding: 16px;
}

.koncni_pregled_narocila .kupec .dodatno {
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  margin-top: 4px;
  color: #888888;
}

.koncni_pregled_narocila .kupec .dodatno A {
  color: #888888;
  text-decoration: none;
}

.koncni_pregled_narocila .kupec .dodatno A:hover {
  color: #888888;
  text-decoration: underline;
}

.koncni_pregled_narocila .podjetje {
  position: absolute;
  top: 58px;
  left: 600px;
}

.koncni_pregled_narocila .veljavnost {
  font-weight: bold;
  font-size: 13px;
  position: absolute;
  top: 150px;
  left: 600px;
}

.koncni_pregled_narocila .odposlji_btn {
  position: absolute;
  top: 237px;
  left: 599px;
  width: 200px;
}

.oddaj_narocilo_fin_btn {
  margin-top: 7px;
  background: url(/static/img/odposlji_narocilnico.png);
  border: 0 none;
  height: 36px;
  text-indent: -9999px;
  width: 182px;
  background-repeat: no-repeat;
  outline: none;
  margin-bottom: 23px;
  margin-left: 670px;
  BODY.de_at &{
    background: url(/static/img/de_at/bestellung_large.png);
    width: 177px;
    height: 33px;
  }
  BODY.hr &{
    background: url(/static/img/hr/posalji-upit.png);
    width: 140px;
    height: 33px;
    margin-left: 608px;
  }
}

.oddaj_narocilo_fin_btn.inside {
  margin-left: 578px;
  margin-top: 25px;
  BODY.de_at &{
    margin-left: 583px;
  }
}

.koncni_pregled_narocila .odposlji_btn .oddaj_narocilo_fin_btn,
BODY.de_at .koncni_pregled_narocila .odposlji_btn .oddaj_narocilo_fin_btn{
  margin-left: 0;
}

.koncni_pregled_narocila .narocilnica_footnote {
  margin-top: 90px;
  font-size: 11px;
  color: #666666;
  text-align: center;
}

.register_prompt {
  width: 550px;
  padding: 5px 25px 18px;
  background-color: #EEEEEE;
  margin-bottom: 20px;
}

.register_prompt p {
  font-size: 13px;
  margin-bottom: 15px;
}

.register_prompt h2 {
  margin-top: 10px;
  margin-bottom: 8px;
}

.register_prompt .input_geslo {
  width: 141px;
  margin-right: 15px;
}

.lastnosti_narocila {
  margin: 25px 0 0;
  padding: 10px 13px;
  width: 303px;
  background-color: #f3f3f3;
}
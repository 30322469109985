UL.accordion {
    padding-left: 0;
    list-style: none;
}

.accordion {
    IMG {
        max-width: 100% !important;
    }
}

UL.accordion LI.accordion__item { //Longer selector for specificity reasons :(
    list-style: none;
    //padding: 0;
    margin: 7px 0;
    position: relative;
    border: 1px solid #EEEEEE;
    padding: 11px 6px 0 15px;
}

.accordion__item {
    border: 1px solid #EEEEEE;
    min-height: 25px;
    cursor: pointer;
    background-color: #f8f8f8;
}

.accordion__item--opened {
    background-color: transparent;
    cursor: auto;
}

.accordion__item__header {
    cursor: pointer;
}

H3.accordion__item__title {
    margin: 0;
    color: black;
    //color: $colorPrimary;
    font-size: 13px;
    font-weight: bold;
    //text-decoration: underline;
    border-bottom: 1px dotted black;
    display: inline-block;
    line-height: 1;
}

.accordion__toggle-btn {
    background-color: $colorPrimary;
    height: 24px;
    width: 24px;
    position: absolute;
    top: 6px;
    right: 6px;
    background-image: url(/bundles/app/areas/accordion/accordion-toggle-plus.png);
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

.accordion__item--opened .accordion__toggle-btn {
    background-image: url(/bundles/app/areas/accordion/accordion-toggle-minus.png);
}

.accordion__item__content {
    position: relative;
    top: 8px;
    border-top: 1px solid #EEEEEE;
    display: none;
}
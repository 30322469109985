/***  Navigation side  ***/
DIV#navigation_side {
  /*+placement:anchor-top-left 3px 309px;*/
  position: absolute;
  left: 3px;
  top: 250px;
}
DIV#navigation_side DIV.menu_group_label {
  font-family: $fontFamilyStandard;
  font-weight: normal;
  color: #11598C;
  margin-bottom: 4px;
  font-size: 17px;
  padding-left: 10px;
  margin-top: -16px;
}
UL.navigation_side {
  width: 170px;
  padding-left: 0px;
  list-style: none;
  font-size: 12px;
  padding-top: 0px;
  margin-top: -1px;
  margin-bottom: 31px;
  font-weight: bold;
}
UL.navigation_side A {
  color: #555555;
  text-decoration: none;
}
UL.navigation_side A:hover {
  color: #000000;
  text-decoration: underline;
}
UL.navigation_side > LI {
  padding-left: 11px;
  padding-top: 10px;
  padding-right: 19px;
  padding-bottom: 10px;
  font-family: Arial, Helvetica, sans-serif;
  border-left: 1px solid #DEDEDE;
  border-bottom: 1px solid #DEDEDE;
  border-top: 1px solid #FFFFFF;
  background-image: url(/static/img/side_menu_back.jpg);
  font-size: 13px;
  position: relative;
  padding: 9px 19px 9px 10px;
}
UL.navigation_side > LI:first-child {
  -moz-border-radius: 8px 0 0;
  -webkit-border-radius: 8px 0 0;
  border-radius: 8px 0 0;
  border-top: 1px solid #DEDEDE;
}
UL.navigation_side > LI:only-child {
  -moz-border-radius: 8px 0 0 8px !important;
  -webkit-border-radius: 8px 0 0 8px !important;
  border-radius: 8px 0 0 8px !important;
  border-top: 1px solid #DEDEDE;
}
UL.navigation_side > LI:last-child {
  -webkit-border-radius: 0 0 0 8px;
  -moz-border-radius: 0 0 0 8px;
  border-radius: 0 0 0 8px;
}
UL.navigation_side > LI.active {
  background-color: #2F6493;
  background-image: none;
  /*[disabled]border:solid #2F6493;*/
  /*[disabled]border-width:1px 0 0 1px;*/
  border-width: 0;
  padding-top: 11px;
  padding-bottom: 11px;
}
UL.navigation_side > LI.active A {
  color: #FFFFFF;
  text-shadow: 1px 1px #000000;
}
UL.navigation_side LI.subfolder {
  padding: 0px;
}
UL.navigation_side LI.folder DIV.menu_arrow {
  /*+placement:anchor-top-right 9px 13px;*/
  position: absolute;
  right: 9px;
  top: 13px;
  top: 12px;
  background-image: url(/static/img/sprite_icons_png.png);
  background-repeat: no-repeat;
  width: 6px;
  height: 11px;
  background-position: -13px 0px;
}
UL.navigation_side LI.folder.active DIV.menu_arrow {
  /*+placement:anchor-top-right 9px 14px;*/
  position: absolute;
  right: 9px;
  top: 14px;
  background-image: url(/static/img/sprite_icons_png.png);
  background-repeat: no-repeat;
  width: 8px;
  height: 11px;
  background-position: -2px 0px;
}

UL.navigation_side LI.folder.expanded DIV.menu_arrow {
    position: absolute;
    right: 9px;
    top: 12px;
    background-image: url(/static/img/sprite_icons_png.png);
    background-repeat: no-repeat;
    width: 8px;
    height: 11px;
    background-position: -2px 0px;
}

/***  Navigation side - sub*/
UL.navigation_side_sub {
  padding: 4px 0 5px;
  margin: 0px;
  color: #8F8F8F;
  list-style: none;
  background-color: #FFFFFF;
}
UL.navigation_side > LI:only-child UL.navigation_side_sub {
  -webkit-border-radius: 0 0 0 8px;
  -moz-border-radius: 0 0 0 8px;
  border-radius: 0 0 0 8px;
}
UL.navigation_side > LI:last-child UL.navigation_side_sub {
  -webkit-border-radius: 0 0 0 8px;
  -moz-border-radius: 0 0 0 8px;
  border-radius: 0 0 0 8px;
}
UL.navigation_side_sub LI {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 13px;
  background-image: url(/static/img/side_menu_bullet.png);
  background-repeat: no-repeat;
  background-position: 0 9px;
  font-size: 11px;
  margin-left: 11px;
  width: 134px;
}
UL.navigation_side_sub LI A:hover {
  text-decoration: underline;
}
UL.navigation_side_sub LI DIV.menu_arrow {
  display: none;
}
UL.navigation_side_sub LI.active {
  background-image: url(/static/img/side_menu_bullet_active.png) !important;
  background-position: 0px 11px;
}
UL.navigation_side_sub LI.active A {
  color: #11598C;
}
/*** Navigation side - konfigurator*/
UL.menu_konfigurator {
  list-style: none;
  font-size: 12px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-left: 11px;
  padding-top: 8px;
  padding-bottom: 1px;
  background-color: #FFFFFF;
  font-weight: bold;
}
UL.menu_konfigurator UL {
  padding-left: 0px;
  list-style: none;
  margin-bottom: 8px;
  margin-top: 6px;
}
UL.menu_konfigurator UL LI {
  padding-bottom: 4px;
  width: 132px;
  position: relative;
  padding-left: 20px;
  font-weight: normal;
  margin-left: 2px;
}
UL.menu_konfigurator UL LI A {
  color: #7D7D7D;
}
UL.menu_konfigurator UL LI.active {
  /*[disabled]font-weight:bold;*/
}
UL.menu_konfigurator UL LI.active A {
  color: #000000;
}
UL.menu_konfigurator UL LI INPUT {
  margin-right: 5px;
  /*+placement:anchor-top-left 0px 2px;*/
  position: absolute;
  left: 0px;
  top: 2px;
}


/*** Catalogue download ***/
.side_catalogue_download {
  border: 1px solid #eaeaea;
  $widthTotal: 145px;
  $sidePadding: 0px;
  padding: 4px $sidePadding;
  width: $widthTotal - $sidePadding*2;
  background-color: #FBFBFB;

  margin-left: 9px;
  text-align: center;
  @include border-radius(10px);

  .katalog{
    margin-top:7px;
    margin-left: 7px;
  }

  A{
    text-align: center;
    position: relative;
    margin-bottom: 4px;
    text-decoration: none;
    display: inline-block;
    line-height: 1em;
    color: #707070;
    font-size: 11px;

    .big-link{
      font-family: Arial, Helvetica, sans-serif;
      font-size: 14px;
      font-weight: bold;
      color: $colorPrimary;
      margin: 8px 0;
    }

    .desc{
      font-size: 11px;
      color: #5F5F5F;
      margin: 8px 0;
    }
  }

}

@mixin border-radius($radius) {
  border-radius: $radius;
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
}

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin hide-text() {
  $approximate-em-value: 12px / 1em;
  $wider-than-any-screen: -9999em;
  text-indent: $wider-than-any-screen * $approximate-em-value;
  overflow: hidden;
  text-align: left;
}

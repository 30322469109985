.vizi-number-field{
  @include clearfix();
  display: inline-block;
  height: 18px;
  vertical-align: middle;
  margin-right: 3px;
  margin-top: -2px;
  border: 1px solid #dddddd;

  INPUT{
    float: left;
    font-size: 12px;
    height: 18px;
    width: 16px;
    text-align: center;
    padding: 0 5px;
    margin: 0;
    border: 1px solid #ddd;
    border-width: 0 1px;
  }

  .vnf-inc-btn{
    padding-top: 2px;
    width: 12px;
    height: 16px;
    float: left;
    font-size: 11px;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
    background-color: #eaeaea;
    color: $colorPrimary;
    -moz-user-select     : none;
    -khtml-user-select   : none;
    -webkit-user-select  : none;
    -o-user-select       : none;
    user-select          : none;

    &:hover{
      background-color: #dedede;
    }
  }


  .vnf-add{

  }

}
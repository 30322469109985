.reference-project {
  display: flex;
  flex-wrap: wrap;
  
  &__title {
    flex: 0 0 100%;
    font-size: 19px;
    //color: $colorSecondary;
    //color: $colorText;
  }
  
  &__main-image {
    flex: 0;
    flex-basis: 290px;
    
    A {
      display: block;
      width: 100%;
    }
    
    IMG {
      width: 100%;
      height: auto;
    }
  }
  
  &__body {
    flex: 1;
    padding: 0 10px;
    
  }
  
  &__description {
    margin-bottom: 10px;
  }
  
  &__thumbnails {
    display: flex;
    flex-wrap: wrap;
    
  }
  
  &__thumbnails-item {
    list-style: none;
    padding: 0 5px 5px 0;
  }
}
$pageWidth: 1000px;

//#########
// Colors
//#########
$colorText: #1b2024;
$colorPrimary: #11598C;
$colorSecondary: #7BA30B;

$colorLink: #828282;


$fontFamilyStandard: Ubuntu, Arial, Helvetica, sans-serif;
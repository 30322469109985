/*** HTML5 Boilerplate  ***/
/*+clearfix {*/
DIV#breadcrumbs:after, UL.js-tabs-labels:after, UL.tabela LI:after, DIV.pagination:after, DIV.pagination UL:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
  font-size: 0;
}
DIV#breadcrumbs, UL.js-tabs-labels, UL.tabela LI, DIV.pagination, DIV.pagination UL {
  display: inline-block;
}
DIV#breadcrumbs, UL.js-tabs-labels, UL.tabela LI, DIV.pagination, DIV.pagination UL {
  /*\*/
  display: block;
  /**/
  -height: 1px;
}
/*+}*/
ARTICLE, ASIDE, DETAILS, FIGCAPTION, FIGURE, FOOTER, HEADER, HGROUP, NAV, SECTION {
  display: block;
}

AUDIO:not([controls]) {
  display: none;
}
[hidden] {
  display: none;
}
HTML {
  font-size: 12px;
  -webkit-text-size-adjust: 100%;
  overflow-y: scroll;
  font-family: Arial, Helvetica, sans-serif;
}
HTML, BUTTON, INPUT, SELECT, TEXTAREA {
  font-family: sans-serif;
  color: #1F1F1F;
}
BODY {
  margin: 0;
  font-size: 1em;
  line-height: 1.4;
  font-family: arial, helvetica, sans-serif;
  background-image: url(/static/img/bkg.png);
  height: 100%;
}
::-moz-selection {
  background: #3399FF;
  color: #FFF;
  text-shadow: 0px 0px;
}
::selection {
  background: #3399FF;
  color: #FFF;
  text-shadow: 0px 0px;
}
A {
  color: #11598C;
}
A:visited {
  color: #11598C;
}
A:hover {
  color: #000000;
}
/*a:focus { outline: 1px dotted; }*/
A:hover, A:active {
  outline: 0;
}
ABBR[title] {
  border-bottom: 1px dotted #000000;
}
B, STRONG {
  font-weight: bold;
}
BLOCKQUOTE {
  margin: 1em 40px;
}
DFN {
  font-style: italic;
}
HR {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #CCC;
  margin: 1em 0;
  padding: 0;
}
INS {
  background: #FF9;
  color: #000;
  text-decoration: none;
}
MARK {
  background: #FF0;
  color: #000;
  font-style: italic;
  font-weight: bold;
}
PRE, CODE, KBD, SAMP {
  font-family: monospace, serif;
  -font-family: "courier new", monospace;
  font-size: 1em;
}
PRE {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}
Q {
  quotes: none;
}
Q:before, Q:after {
  content: "";
  content: none;
}
SMALL {
  font-size: 85%;
}
SUB, SUP {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
SUP {
  top: -0.5em;
}
SUB {
  bottom: -0.25em;
}
UL, OL {
  margin: 1em 0;
  padding: 0 0 0 40px;
}
DD {
  margin: 0 0 0 40px;
}
NAV UL, NAV OL {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
}
IMG {
  border: 0;
  -ms-interpolation-mode: bicubic;
  vertical-align: middle;
}
/*svg:not(:root)*/
#INVALID-RULE {
  overflow: hidden;
}
FIGURE {
  margin: 0;
}
FORM {
  margin: 0;
}
FIELDSET {
  border: 0;
  margin: 0;
  padding: 0;
}
LABEL {
  cursor: pointer;
}
LEGEND {
  border: 0;
  margin-left: -7px; /*[e]- 7 px*/
  padding: 0;
  white-space: normal;
}
BUTTON, INPUT, SELECT, TEXTAREA {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
}
BUTTON, INPUT {
  line-height: normal;
}
BUTTON, INPUT[type="button"], INPUT[type="reset"], INPUT[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button;
}
BUTTON[disabled], INPUT[disabled] {
  cursor: default;
}
INPUT[type="checkbox"], INPUT[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
INPUT[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
INPUT[type="search"]::-webkit-search-decoration, INPUT[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
BUTTON::-moz-focus-inner, INPUT::-moz-focus-inner {
  border: 0;
  padding: 0;
}
TEXTAREA {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}
INPUT:valid, TEXTAREA:valid {
}
INPUT:invalid, TEXTAREA:invalid {
  //background-color: #F0DDDD;
}
TABLE {
  border-collapse: collapse;
  border-spacing: 0;
}
TD {
  vertical-align: top;
}
.chromeframe {
  margin: 0.2em 0;
  background: #CCC;
  color: #000000;
  padding: 0.2em 0;
}
.ir {
  display: block;
  border: 0;
  text-indent: -999em;
  overflow: hidden;
  background-color: transparent;
  background-repeat: no-repeat;
  text-align: left;
  direction: ltr;
}
.ir BR {
  display: none;
}
.hidden {
  display: none !important;
  visibility: hidden;
}
.visuallyhidden {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.visuallyhidden.focusable:active, .visuallyhidden.focusable:focus {
  clip: rect(auto, auto, auto, auto);
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}
.invisible {
  visibility: hidden;
}
.clearfix:before, .clearfix:after {
  content: "";
  display: table;
}
.clearfix:after {
  clear: both;
}
.clearfix {
  zoom: 1;
}

/*** HTML 5 boilerplate end **/